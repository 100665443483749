<template>
  <div>
    <b-card>
      <b-table
        show-empty
        striped
        hover
        class="bg-white cursor-icon text-left"
        :items="jobTemplete"
        :fields="fields"
      >
        <template #cell(category)="data">
          <div>
            <span>{{ data.value.name }}</span>
          </div>
        </template>
        <template #cell(working)="data">
          <div>
            <span>{{ data.item.hours }}</span>
          </div>
        </template>
        <template #cell(skill)="row">
          {{ getSkill(row.item.skill) }}
        </template>
        <template #cell(action)="row">
          <b-link
            v-if="$can('view template', 'Job Post')"
            :to="`/job/get-template/${row.item.category_id}`"
          >
            <b-button
              v-b-tooltip.hover
              title="View"
              size="sm"
              class="preview rounded-circle mr-1"
            >
              <img width="15" src="@/assets/images/icons/eye.png" alt="" />
            </b-button>
          </b-link>

          <b-link
            v-if="$can('update template', 'Job Post')"
            :to="`/job/get-template-edit/${row.item.category_id}`"
          >
            <b-button
              v-b-tooltip.hover
              title="Edit"
              size="sm"
              class="preview rounded-circle"
            >
              <img width="15" src="@/assets/images/icons/edit.png" alt="" />
            </b-button>
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BLink,
  BRow,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BTable,
    BLink,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    jobTemplete: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      skills: [],
      fields: [
        { key: "category", label: "Category", sortable: true },
        { key: "working", label: "Working Hour" },
        { key: "skill", label: "Skills" },
        { key: "action", label: "Action" },
      ],
    };
  },
  created() {
    this.getSkills();
  },
  computed: {},
  methods: {
    getSkill(skills) {
      if (skills && skills.length > 0 && this.skills.length > 0) {
        let skill = "";
        skills.forEach((element) => {
          let skillName = this.skills.find((x) => x.id === element.skill_id);
          skill += skillName.skill + " (" + element.skill_level + ")" + ", ";
        });
        return skill.slice(0, -2);
      }
    },
    publishJob(id) {
      this.$store.dispatch("job/publishJob", { id: id, status: 1 }).then(() => {
        this.$emit("getJobs");
      });
    },
    getSkills() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("skill/getSkills", query).then((response) => {
        this.skills = response.data;
      });
    },
    // alert function
    deleteJob(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("job/deleteJob", id).then((res) => {
            this.$emit("getJobs");
            console.log(res);
          });
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
li {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important; /* number of lines to show */
  line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
.requirement {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important; /* number of lines to show */
  line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  font-size: 12px !important;
}

.detail-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Text Additional */

  color: #79869f;
}

.btm-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;

  /* Text 2 */

  color: #a8adaf;
}

.btn-secondary {
  border-color: white !important;
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.publish-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
  border-color: white !important;
}

.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

button.btn.preview.rounded-circle.btn-secondary.btn-sm {
  padding: 10px;
}

.btn-bg {
  border-color: white !important;
}

.item {
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.item span {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #79869f;
}

.item img {
  width: 16px;
  height: 16px;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #7190ef;
}

@media only screen and (min-width: 1440px) {
  li {
    display: block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 5 !important; /* number of lines to show */
    line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
  .card-size {
    display: block;
    width: 485px;
    height: 300px;
  }

  .top-part {
    height: 180px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1050px) {
  .top-part {
    /* display: none !important; */
    height: 140px !important;
  }

  .title {
    font-weight: 600 !important;
    font-size: 15px !important;

    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    color: #7190ef;
  }

  .card-size {
    display: block !important;
    width: 310px;
    height: 260px !important;
    padding: 10px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 800px) {
  .card-size {
    display: block;
    height: 310px;
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #7190ef;
  }

  .top-part {
    height: 160px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  #employee-list .v-select.vs--single.vs--searchable {
    width: 100px;
  }
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  #employee-list .v-select.vs--single.vs--searchable {
    width: 100%;
  }
}
</style>
